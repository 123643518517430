import Base = require("Everlaw/Base");
import Checkbox = require("Everlaw/UI/Checkbox");
import Cmp = require("Everlaw/Core/Cmp");
import DateUtil = require("Everlaw/DateUtil");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import LabeledIcon = require("Everlaw/UI/LabeledIcon");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import Table = require("Everlaw/Table");
import Util = require("Everlaw/Util");
import { objectToQuery } from "Everlaw/Core/Obj";

/**
 * Widget for browsing/selecting Google Vault exports.
 * Google Vault data is organized into "Matters" (which map roughly to our databases) which
 * contain "exports" (individual data dumps, map roughly to our datasets).
 */

export class Matter extends Base.Object {
    className: "VaultMatter";
    override id: string;
    name: string;
    state: string;
    description: string;
    // We have to fetch results in pages; to avoid jarring the user when adding new pages we sort
    // by the batch first.
    batch: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        this.batch = params.batch;
        this.name = params.name;
        this.state = params.state;
        this.description = params.description;
    }
    override compare(other: Matter) {
        return Cmp.num(this.batch, other.batch) || Cmp.str(this.name, other.name);
    }
    override display() {
        return this.name;
    }
}

const icons = {
    HANGOUTS_CHAT: "google-hangouts-logo",
    MAIL: "google-gmail-logo",
    DRIVE: "google-drive-logo",
    GROUPS: "google-groups-logo",
};

export class Export extends Base.Object {
    className: "VaultExport";
    override id: string;
    matterId: string;
    name: string;
    // I think the only relevant status is "COMPLETED", for exports that are ready to be fetched.
    status: string;
    // Size in bytes of the export
    size: number;
    // Doc count in the export
    count: number;
    // The source of the data being exported - DRIVE, MAIL, HANGOUTS_CHAT or GROUPS
    corpus: "DRIVE" | "MAIL" | "HANGOUTS_CHAT" | "GROUPS";
    created: number;
    creator: string;
    // The email accounts the export was requested for (may be null)
    emails: string[];
    // We have to fetch results in pages; to avoid jarring the user when adding new pages we sort
    // by the batch first.
    batch: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        this.matterId = params.matterId;
        this.name = params.name;
        this.status = params.status;
        this.size = parseInt(params.stats.sizeInBytes);
        this.count = parseInt(params.stats.exportedArtifactCount);
        this.corpus = params.query.corpus;
        this.created = Date.parse(params.createTime);
        this.batch = params.batch;
        this.emails = params.query.accountInfo && params.query.accountInfo.emails;
        this.creator = params.requester.displayName;
    }
    override compare(other: Export) {
        return Cmp.num(this.batch, other.batch) || Cmp.str(this.name, other.name);
    }
    override display() {
        return this.name;
    }
    isReady() {
        return this.status === "COMPLETED";
    }
    icon() {
        const icon = new Icon(Util.getDefault(icons, this.corpus, "google-vault-logo"));
        Dom.style(icon, "marginRight", "0");
        return icon;
    }
}

interface ExportData extends Table.RowData {
    checkbox: Checkbox;
}

export class Picker {
    private exportNode: HTMLElement;
    private inner: HTMLElement;
    private exportTable: Table<Export, ExportData>;
    private selector: SingleSelect<Matter>;
    private batch = 0;
    // This is used to check that we haven't changed matters when handling asychronous Export fetches.
    // We use an object instead of the id directly to handle the case where the user switches from
    // matter A to a different matter B and then back to A before the original request finishes.
    private selectedMatter: { id: string };
    private selectedExports: { [id: string]: Export };
    private exportStore = new Base.JsonStore(Export, false);
    private queryDialog: QueryDialog;
    constructor(
        private oauthToken: string,
        onSubmit: (exports: Export[]) => void,
    ) {
        const box = Dom.create("div", {
            style: {
                width: "100%",
                height: "535px",
                padding: "18px",
            },
        });
        this.inner = Dom.create("div", box);
        Dom.create("h6", { content: "Select a matter", style: "margin-bottom: 8px" }, this.inner);
        this.selector = new SingleSelect<Matter>({
            elements: [],
            placeholder: "Loading vault matters...",
            headers: false,
            popup: "after",
            onSelect: (matter) => {
                this.selector.blur();
                this.selectedMatter = { id: matter.id };
                this.clearExports();
                this.fetchExports();
            },
        });
        this.selector.setDisabled(true);
        Dom.place(this.selector, this.inner);
        this.exportNode = Dom.create("div", this.inner);
        Dom.create(
            "h6",
            { content: "Select export(s)", style: "margin-top: 16px" },
            this.exportNode,
        );
        this.exportTable = new Table({
            store: this.exportStore,
            parentNode: this.exportNode,
            empty: "Loading...",
            columns: [
                { style: "width: 30px" },
                { style: "width: 60px; text-align: center" },
                { class: "description" },
                { style: "width: 150px" },
                { style: "width: 150px" },
                { style: "width: 100px" },
                { style: "width: 150px" },
                { style: "width: 100px" },
            ],
            cells: [
                (p) => {
                    if (p.firstTime) {
                        p.data.checkbox = new Checkbox({
                            parent: p.td,
                            // We don't want clicking on the checkbox to toggle it; we'll handle that
                            // using the row click callback.
                            preventDefault: true,
                        });
                        p.data.destroyables.push(p.data.checkbox);
                    }
                    p.data.checkbox.setDisabled(!p.o.isReady());
                    const isSelected = p.o.id in this.selectedExports;
                    p.data.checkbox.set(isSelected, true);
                    Dom.toggleClass(p.tr, "selected", isSelected);
                },
                (p) => {
                    Dom.setContent(p.td, p.o.icon().node);
                },
                (p) => {
                    Dom.setContent(p.td, p.o.name);
                },
                (p) => {
                    Dom.setContent(p.td, p.o.creator);
                },
                (p) => {
                    Dom.setContent(p.td, DateUtil.displayShortDateTimeLocal(p.o.created));
                },
                (p) => {
                    Dom.setContent(p.td, Is.number(p.o.size) ? Util.displayFileSize(p.o.size) : "");
                },
                (p) => {
                    Dom.setContent(
                        p.td,
                        Is.number(p.o.count) ? Util.countOf(p.o.count, "doc") : "",
                    );
                },
                (p) => {
                    Dom.setContent(p.td, p.o.isReady() ? "Ready" : "Not Ready");
                },
            ],
            header: [
                "",
                "Source",
                "Export Name",
                "Exported By",
                "Created",
                "Size",
                "Count",
                "Status",
            ],
            scrollPad: 8,
            maxHeight: "360px",
            onClick: (obj) => {
                if (obj.isReady()) {
                    if (obj.id in this.selectedExports) {
                        delete this.selectedExports[obj.id];
                    } else {
                        this.selectedExports[obj.id] = obj;
                    }
                    this.queryDialog.disableSubmit(
                        !Object.values(this.selectedExports || {}).length,
                    );
                    this.exportStore.publish(obj);
                }
            },
        });
        Dom.hide(this.exportNode);
        this.fetchMatters();
        this.queryDialog = QueryDialog.create({
            title: "Google Vault exports",
            prompt: box,
            submitIsSafe: true,
            onSubmit: () => {
                onSubmit(Object.values(this.selectedExports || {}));
                return true;
            },
            submitText: "Next",
            style: {
                width: "900px",
                maxHeight: "none",
            },
        });
        this.queryDialog.registerDestroyable([this.exportTable, this.selector]);
        this.queryDialog.disableSubmit(true);
    }
    private clearExports() {
        // Clear all our currently fetched exports (e.g. when switching matters)
        this.selectedExports = {};
        this.queryDialog.disableSubmit(true);
        this.exportStore.clear();
        this.exportTable.empty = "Loading...";
        this.exportTable.refresh();
    }
    private fetchExports(token: string = null) {
        // Fetch the first/next page of exports for the current matter.
        Dom.show(this.exportNode);
        const data = { pageToken: token };
        const matter = this.selectedMatter;
        this.fetchJson(`/matters/${matter.id}/exports`, data, (exports, batch) => {
            if (this.selectedMatter === matter) {
                this.loadExports(exports, batch, !token);
            }
        });
    }
    private loadExports(
        res: { exports: any[]; nextPageToken?: string },
        batch: number,
        first: boolean,
    ) {
        if (res.exports) {
            this.exportStore.setAll(
                res.exports.map((m) => {
                    return Object.assign(m || {}, { batch: batch });
                }),
            );
            if (res.nextPageToken) {
                this.fetchExports(res.nextPageToken);
            }
        } else if (first) {
            this.exportTable.empty = "No exports.";
            this.exportTable.refresh();
        }
    }
    private fetchMatters(token: string = null) {
        // Fetch the first/next page of matters.
        const data = {
            state: "OPEN",
            pageToken: token,
        };
        this.fetchJson("/matters", data, (matters, batch) => {
            this.loadMatters(matters, batch, !token);
        });
    }
    private fetchJson(path: string, data: {}, onLoad: (resp: any, batch: number) => void) {
        const currBatch = this.batch;
        this.batch += 1;
        const url = `https://vault.googleapis.com/v1${path}`;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", `${url}?${objectToQuery(data)}`, true);
        xhr.setRequestHeader("Authorization", `Bearer ${this.oauthToken}`);
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (xhr.status === 200) {
                let res: { matters: any[]; nextPageToken?: string };
                try {
                    res = JSON.parse(xhr.responseText); // throws SyntaxError on bad/truncated JSON
                } catch (error) {
                    this.error();
                }
                onLoad(res, currBatch);
            } else {
                this.error();
            }
        };
        xhr.send();
    }
    private loadMatters(
        res: { matters: any[]; nextPageToken?: string },
        batch: number,
        first: boolean,
    ) {
        this.selector.tb.setPlaceholder("Enter portion of name...");
        if (res.matters) {
            this.selector.setDisabled(false);
            this.selector.addMultiple(
                res.matters.map((m) => {
                    return new Matter({
                        name: m.name,
                        id: m.matterId,
                        state: m.state,
                        description: m.description,
                        batch: batch,
                    });
                }),
            );
            if (res.nextPageToken) {
                this.fetchMatters(res.nextPageToken);
            }
        } else if (first) {
            this.selector.tb.setPlaceholder("No vault matters found.");
        }
    }
    private error() {
        // Hide all the picker UI and display an error message.
        const errorIcon = new LabeledIcon("alert-circle-red", {
            label: "Unable to load Vault data. Please contact Everlaw support or try again later.",
        });
        this.queryDialog.disableSubmit(true);
        Dom.setContent(
            this.inner,
            Dom.div(
                {
                    class: "centered",
                },
                errorIcon.node,
            ),
        );
    }
}
