import { AuthSource } from "Everlaw/AuthSource";
import Bugsnag = require("Everlaw/Bugsnag");
import Str = require("Everlaw/Core/Str");
import Dom = require("Everlaw/Dom");
import GoogleVault = require("Everlaw/GoogleVault");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import SharefilePicker = require("Everlaw/SharefilePicker");
import Dialog = require("Everlaw/UI/Dialog");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import TextBox = require("Everlaw/UI/TextBox");
import Util = require("Everlaw/Util");
import Window = require("Everlaw/Window");
import {
    boxAuthUrl,
    dropboxAuthURL,
    googleAuthURL,
    microsoftAdminConsentUrl,
    microsoftGraphAuthURL,
    sharefileAuthUrl,
    slackAuthURL,
    SecurityTokenResponse,
    generateTokenAndRedirectUri,
} from "Everlaw/CloudConnectors/CloudConnectorAuthorization";
import UploadSource = require("Everlaw/Model/Upload/UploadSource");

declare let window: Window & {
    onBoxComplete: (files: any[], pickerWindow: Window) => void;
    onGoogleDriveComplete: (response: google.picker.Response, pickerWindow: Window) => void;
    restrictedGoogleDrive?: boolean;
    onGoogleVaultComplete: (authToken: string, authWindow: Window) => void;
    onGoogleVaultPartialPermissionError: (authWindow: Window) => void;
    onDropboxComplete: (authWindow: Window) => void;
    onGraphUserComplete: (authWindow: Window, authToken: string) => void;
    onGraphAppComplete: (authWindow: Window) => void;
    onSharefileComplete: (pickerWindow: Window) => void;
    onSlackComplete: (pickerWindow: Window) => void;
    /**
     * As of TS 4.3 opener is no longer typed as any, but as Window.
     * The lib.dom Window type does not include these methods here so we override the type of opener
     * to match the new intersection type declared here.
     */
    opener: typeof window;
};

// makes sure that the type of the window corresponds to window
export function getWindow() {
    return window;
}
/**
 * This interface is slightly more complicated than the base File, since it has some additional
 * functionality we want to make available for DirectoryTar readers. For normal File objects,
 * you can use the SingleFileWithId wrapper class below.
 */
export interface FileLike {
    name: string;
    // The file size. This may not be accurate until sizeIsLoaded() returns true!
    // It should always be at least equal to the endpoint of the most recent slice, though.
    size: number;
    // A sha1 hash of the file (or something nearly equivalent) to store in the source sha1Hash field.
    // This can be set lazily.
    sha1Hash: string;
    source: UploadSource;
    // Slice the given range of this file-like object.
    slice(start: number, end: number): Promise<Blob>;
    // Fast forward (FF) to the given offset in this file.
    // The semantics of this are slightly different than seeking in a normal file - in particular,
    // we guarantee that once we've FFed to a certain offset, we'll never need to slice any data
    // earlier than it again (or FF earlier than it - these will always be monotonically increasing).
    // This means that the file-like object can discard any info about data earlier than the most
    // recent FF.
    fastForward(offset: number): void;
    // We allow the size of these files to be lazily-computed.
    sizeIsLoaded(): boolean;
    isEmpty(): Promise<boolean>;
    // Have we encountered an error with reading data from this file (or computing its size, etc)?
    isError(): boolean;
    // Stop any ongoing tasks related to reading this file, computing its size, etc.
    // The input flag indicates whether this is an abort due to an error (as opposed to a user request).
    // You should return a promise that resolves to a list of error messages (that you have encountered
    // already, or, if `onError` is true, after some additional probing of your current state).
    abort(onError: boolean): Promise<string[]>;
}

export interface FileWithId extends FileLike {
    id?: string | number;
    // used for OneDrive
    driveId?: string;
    // Some cloud sources provide custodian information.
    custodians?: string[];
    // Some services report folders as having a size of 0.
    // isFolder lets checkFileLengths know that these folders are not empty files.
    // Services that don't cause problems with checkFileLength may not set isFolder.
    isFolder?: boolean;
}

class CloudFile implements FileWithId {
    readonly sha1Hash: string = null;
    readonly name: string;
    readonly size: number;
    readonly source: UploadSource;
    readonly id: string | number;
    readonly driveId: string;
    readonly custodians: string[];
    readonly isFolder: boolean;
    constructor(params: {
        name: string;
        size: number;
        source: UploadSource;
        id?: string | number;
        driveId?: string;
        custodians?: string[];
        isFolder?: boolean;
    }) {
        this.name = params.name;
        this.size = params.size;
        this.source = params.source;
        this.id = params.id;
        this.driveId = params.driveId;
        this.custodians = params.custodians;
        this.isFolder = !!params.isFolder;
    }
    slice(start: number, end: number): Promise<Blob> {
        return Promise.reject();
    }
    fastForward(offset: number): void {}
    sizeIsLoaded(): boolean {
        return true;
    }
    isEmpty() {
        // Google Drive doesn't provide size data, so we have to assume those items are not empty.
        // Dropbox folders also don't include size data.
        return Promise.resolve(
            !(this.source === UploadSource.DROPBOX && this.isFolder)
                && this.size === 0
                && this.source !== UploadSource.GOOGLE_DRIVE,
        );
    }
    isError(): boolean {
        return false;
    }
    abort(onError: boolean): Promise<string[]> {
        return Promise.resolve([]);
    }
}

/**
 * A simple implementation of FileLike based on an actual File.
 * The size is always known immediately and we do not do anything for fast-forwards.
 */
export class SingleFileWithId implements FileWithId {
    readonly size: number;
    readonly name: string;
    readonly isFolder = false;
    sha1Hash: string;
    private worker: Worker;
    constructor(
        readonly file: File,
        readonly source = UploadSource.LOCAL,
    ) {
        this.size = file.size;
        this.name = file.name;
        if (Worker) {
            // Sha1 hasher
            this.worker = new Worker(window.webpackUrl + "/rusha.min.js");
            this.worker.onmessage = (m) => {
                // Save the hash once we're finished.
                this.sha1Hash = m.data.hash;
            };
            this.worker.postMessage({
                id: this.file.name,
                file: this.file,
            });
        }
    }
    slice(start: number, end: number) {
        return Promise.resolve(this.file.slice(start, end));
    }
    fastForward(offset: number) {}
    sizeIsLoaded(): boolean {
        return true;
    }
    isEmpty() {
        return Promise.resolve(this.size === 0);
    }
    abort(onError) {
        if (this.worker) {
            this.worker.terminate();
        }
        return Promise.resolve([]);
    }
    isError(): boolean {
        return false;
    }
}

export interface ExitFunction {
    (files: FileWithId[] | null, source: UploadSource): void;
}

export const MAX_FILES = 50;

export function looksLikeZip(file: FileLike): boolean {
    return Str.endsWith(file.name.toLowerCase(), ".zip");
}

function checkValidFileSelection(files: CloudFile[]): Promise<boolean> {
    if (checkNumFiles(files.length)) {
        return checkEmptyFiles(files);
    }
    return Promise.resolve(false);
}

export function checkNumFiles(numFiles: number): boolean {
    if (!numFiles) {
        Dialog.ok("No files", "No files or folders were detected.");
        return false;
    }
    if (numFiles > MAX_FILES) {
        Dialog.ok(
            "Too many files",
            Dom.div(
                { style: { width: "450px" } },
                "Uploads are limited to "
                    + MAX_FILES
                    + " files. If you need "
                    + " to upload more, put them in a container file (e.g. ZIP) first.",
            ),
        );
        return false;
    }
    return true;
}

export function checkEmptyFiles(files: FileWithId[]): Promise<boolean> {
    return Promise.all(files.map((f) => f.isEmpty())).then((res) => {
        const emptyFiles: FileWithId[] = [];
        res.forEach((empty, i) => {
            if (empty) {
                emptyFiles.push(files[i]);
            }
        });
        if (emptyFiles.length) {
            const alertBody = Dom.div(
                Dom.p("The following files/folders are empty:"),
                Dom.ul(...emptyFiles.map((f) => Dom.li(f.name))),
            );
            Dialog.ok("Empty files/folders", alertBody);
            return false;
        }
        return true;
    });
}

export function initBox(exitFunction: ExitFunction): void {
    generateTokenAndRedirectUri(AuthSource.BOX).then((response: SecurityTokenResponse) => {
        const url = boxAuthUrl(response);
        Window.openCentered(url, "Box login and file picker");
        // attach this function to the window object so that the child window can call via window.opener
        window.onBoxComplete = function (files: any[], pickerWindow: Window) {
            if (!files) {
                return;
            }
            ga_event("File Picker", "Box Submitted");
            // copy pertinent info from files to local variable before closing the window since
            // IE and Edge lock access to it once the picker window closes. We also must use
            // forEach rather than map since they also seem to lock arrays created with map.
            const retFiles: CloudFile[] = [];
            files.forEach((doc) => {
                retFiles.push(
                    new CloudFile({
                        id: doc.id,
                        source: UploadSource.BOX,
                        name: doc.name,
                        size: doc.size,
                        isFolder: doc.type === "folder",
                    }),
                );
            });
            appendCloudFolderName(retFiles);
            // close child window after we successfully get info
            pickerWindow.close();
            checkValidFileSelection(retFiles).then((valid) => {
                if (valid) {
                    exitFunction(retFiles, UploadSource.BOX);
                }
            });
        };
    });
}

export function initGoogleVault(exitFunction: ExitFunction): void {
    generateTokenAndRedirectUri(AuthSource.VAULT).then((response: SecurityTokenResponse) => {
        const url = googleAuthURL(
            response,
            "https://www.googleapis.com/auth/ediscovery.readonly "
                + "https://www.googleapis.com/auth/devstorage.read_only",
        );
        Window.openCentered(url, "Google Vault login and export picker", 500, 675);
        // attach this function to the window object so that the child window can call via window.opener
        window.onGoogleVaultComplete = function (authToken: string, authWindow: Window) {
            ga_event("File Picker", "Google Vault Submitted");
            // Close child window after we successfully get auth info
            authWindow.close();
            new GoogleVault.Picker(authToken, (exports) => {
                // pull pertinent info out
                const files: CloudFile[] = exports.map((e) => {
                    // Convert this to an array now (for some reason it's an object when we get it from
                    // json). Also, we can only map custodians for drive and mail exports.
                    let emails: string[] = null;
                    if (e.emails && (e.corpus === "DRIVE" || e.corpus === "MAIL")) {
                        emails = [];
                        e.emails.forEach((e) => emails.push(e));
                    }
                    return new CloudFile({
                        id: e.id,
                        name: e.name,
                        source: UploadSource.VAULT,
                        size: e.size,
                        driveId: e.matterId,
                        custodians: emails,
                    });
                });
                checkValidFileSelection(files).then((valid) => {
                    if (valid) {
                        exitFunction(files, UploadSource.VAULT);
                    }
                });
            });
        };

        window.onGoogleVaultPartialPermissionError = function (authWindow: Window) {
            authWindow.close();
            permissionErrorDialog("Google Vault");
        };
    });
}

// This constant should match the one found in GoogleDriveHelper.java
export const FOLDER_MIME_TYPE = "application/vnd.google-apps.folder";
// This map should match the one found in GoogleDriveHelper.java
const fileExtensions: Map<string, string> = (() => {
    const fileExtensions = new Map<string, string>();
    fileExtensions.set("application/vnd.google-apps.document", "docx");
    fileExtensions.set("application/vnd.google-apps.spreadsheet", "xlsx");
    fileExtensions.set("application/vnd.google-apps.presentation", "pptx");
    fileExtensions.set("application/vnd.google-apps.drawing", "pdf");
    fileExtensions.set("application/vnd.google-apps.form", "zip");
    return fileExtensions;
})();

// The names generated by this function must match the result of running
// StringUtil.cleanFilename and StringUtil.filenameWithExtension on filename,
// as is done on the backend (otherwise mapping custodians will fail).
function getGoogleDriveExportFilename(filename: string, mimeType: string): string {
    let newName = filename.replace(/[^\w .()-]/g, "_");
    const dotIndex = newName.lastIndexOf(".");
    if (dotIndex !== -1) {
        newName = newName.substring(0, dotIndex);
    }
    const fileExtension = fileExtensions.get(mimeType);
    return fileExtension ? newName + "." + fileExtensions.get(mimeType) : newName;
}

export function initGoogleDrive(exitFunction: ExitFunction): void {
    const org = Project.CURRENT.owningOrg;

    const restricted = org.restrictDrive;

    // Stashing this in the window object so the new opened window can access it.
    // (The new window does not have access to Project.CURRENT, to get to the org.)
    window.restrictedGoogleDrive = restricted;

    generateTokenAndRedirectUri(AuthSource.GOOGLE_DRIVE).then((response: SecurityTokenResponse) => {
        // construct login url
        const scope = `https://www.googleapis.com/auth/${
            restricted ? "drive.file" : "drive.readonly"
        }`;
        const url = googleAuthURL(response, scope);
        // 1053 and 652 are the maximum dimensions for the google drive picker, even though the auth
        // dialog needs about 700px. We add 1 to the height so Edge doesn't add in extra scrollbars.
        Window.openCentered(url, "Google Drive login and file picker", 1053, 653);
        // attach this function to the window object so that the child window can call via window.opener
        window.onGoogleDriveComplete = function (
            response: google.picker.Response,
            pickerWindow: Window,
        ) {
            if (response.action === "cancel") {
                pickerWindow.close();
            } else if (response.action === "picked") {
                ga_event("File Picker", "Google Drive Submitted");
                // copy pertinent info from files to local variable before closing the window since
                // IE and Edge lock access to it once the picker window closes. We also must use
                // forEach rather than map since they also seem to lock arrays created with map.
                const files: CloudFile[] = [];
                response.docs.forEach((doc) => {
                    files.push(
                        new CloudFile({
                            id: doc.id,
                            source: UploadSource.GOOGLE_DRIVE,
                            name: getGoogleDriveExportFilename(doc.name, doc.mimeType),
                            size: doc.sizeBytes,
                            isFolder: doc.mimeType === FOLDER_MIME_TYPE,
                        }),
                    );
                });
                appendCloudFolderName(files);
                // close child window after we successfully get info
                pickerWindow.close();
                checkValidFileSelection(files).then((valid) => {
                    if (valid) {
                        exitFunction(files, UploadSource.GOOGLE_DRIVE);
                    }
                });
            }
        };
    });
}

declare const Dropbox: any;
export function initDropbox(exitFunction: ExitFunction): void {
    generateTokenAndRedirectUri(AuthSource.DROPBOX).then((response: SecurityTokenResponse) => {
        // construct login url
        const url = dropboxAuthURL(response);
        Window.openCentered(url, "Dropbox login and file picker");
        // load dropbox js with parameters matching
        // https://www.dropbox.com/developers/chooser
        const dropboxScript: HTMLScriptElement = document.createElement("script");
        dropboxScript.addEventListener("load", () => {
            // attach this function to the window object so that the child window can call via window.opener
            window.onDropboxComplete = function (authWindow: Window) {
                ga_event("File Picker", "Dropbox Submitted");
                authWindow.close();
                const options = {
                    success: function (files: any[]) {
                        // pull pertinent info out
                        const filesTrimmed: CloudFile[] = files.map((doc) => {
                            return new CloudFile({
                                id: doc.id,
                                source: UploadSource.DROPBOX,
                                name: doc.name,
                                size: doc.bytes,
                                isFolder: doc.isDir,
                            });
                        });
                        appendCloudFolderName(files);
                        checkValidFileSelection(filesTrimmed).then((valid) => {
                            if (valid) {
                                exitFunction(filesTrimmed, UploadSource.DROPBOX);
                            }
                        });
                    },
                    multiselect: true,
                    folderselect: true,
                };
                Dropbox.choose(options);
            };
        });
        dropboxScript.type = "text/javascript";
        // found on admin page for Everlaw Uploads Dropbox app
        dropboxScript.setAttribute("data-app-key", response.clientId);
        dropboxScript.id = "dropboxjs";
        dropboxScript.src = "https://www.dropbox.com/static/api/2/dropins.js";
        document.head.appendChild(dropboxScript);
    });
}

export function initOnedrive(
    exitFunction: ExitFunction,
    selectFolders: boolean,
    isSharePoint: boolean,
): void {
    const serviceName = isSharePoint ? "SharePoint" : "OneDrive";
    generateTokenAndRedirectUri(AuthSource.GRAPH_USER).then((response: SecurityTokenResponse) => {
        // construct login url
        const url = microsoftGraphAuthURL(response, "files.read.all offline_access");
        Window.openCentered(url, serviceName + " login and file picker");

        window.onGraphUserComplete = (authWindow: Window, authToken) => {
            ga_event("File Picker", "OneDrive Submitted");
            authWindow.close();
            const options = {
                clientId: response.clientId,
                action: "query",
                multiSelect: true,
                advanced: {
                    queryParameters: "select=id,name,size,parentReference,folder",
                    redirectUri: window.location.origin + "/oneDrivePicker.html",
                    endpointHint: null,
                    accessToken: null,
                },
                alwaysGetItemWithGraph: false,
                success: (response) => {
                    let files: CloudFile[] = [];
                    if (response.value) {
                        files = response.value.map((file) => {
                            return new CloudFile({
                                name: file.name,
                                id: file.id,
                                source: UploadSource.ONEDRIVE,
                                size: file.size,
                                driveId: file.parentReference.driveId,
                                isFolder: !!file.folder,
                            });
                        });
                    }
                    appendCloudFolderName(files);
                    checkValidFileSelection(files).then((valid) => {
                        if (valid) {
                            exitFunction(files, UploadSource.ONEDRIVE);
                        }
                    });
                },
                error: (e) => {
                    Bugsnag.notify(e);
                    const errorMessage =
                        e.errorCode === "popupOpen"
                            ? "Please verify that popups from everlaw.com are enabled."
                            : "Please verify that the Microsoft account has a OneDrive or SharePoint license";
                    Dialog.ok(
                        "Error loading " + serviceName,
                        errorMessage + " If the problem persists, contact Everlaw support.",
                    );
                },
            };
            /*
             * OneDrive's file picker seems to have been designed under the assumption that it
             * would only be used to either open individual files or save files to folders.
             * However, when called with the action option set to "query" both methods return a
             * similar response that gives us the information we need to get the folders
             * on the backend. The only way to select folders instead of files is to use
             * OneDrive.save() instead of OneDrive.open().
             */
            const openPicker = () => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore this will be modified soon by webpack
                import("/perm/Javascript/OneDrive/7.2.1/OneDrive.debug.js").then(
                    (OneDrive: any) => {
                        selectFolders ? OneDrive.save(options) : OneDrive.open(options);
                    },
                );
            };
            if (isSharePoint) {
                const textBox = new TextBox({ placeholder: "Optional Sharepoint URL" });
                new QueryDialog({
                    onSubmit: () => {
                        const hint = textBox.getValue();
                        if (hint) {
                            /**
                             * In the case where a user wants to access a particular Sharepoint
                             * site, entering it as an endpointHint takes the picker there.
                             * This
                             * is necessary because sometimes a site won't show up in the
                             * "shared libraries" nav on the left of the picker. According to
                             * the docs, there's also
                             * options.advanced.navigation.entryLocation.sharePoint, but it
                             * doesn't seem to work and there are a bunch of > 1 year old forum
                             * posts asking about it so it may be a while before it's fixed.
                             */
                            options.advanced.endpointHint = hint;
                            /**
                             * Here we can skip the separate authentication that the file
                             * picker
                             * does because we have an explicit endpointHint. This is also
                             * necessary because, when we specify an endpointHint, the picker
                             * will log in at that endpoint and the token we get from there
                             * isn't valid with the graph api, but the token we got from the
                             * initial oauth flow is valid.
                             */
                            options.advanced.accessToken = authToken;
                            /**
                             * Specifying an endpoint hint also uses that endpoint when
                             * querying
                             * for the item's properties. Most Sharepoint API endpoints don't
                             * have CORS policies set up, though, whereas graph allows access
                             * from anywhere. We can use the token we got from the initial
                             * login along with the graph API to do the item fetches while
                             * still allowing the endpointHint to take the user to the desired
                             * view.
                             */
                            options.alwaysGetItemWithGraph = true;
                        }
                        openPicker();
                        return true;
                    },
                    title: "Specify Sharepoint URL",
                    prompt: Dom.div(
                        { style: "margin-bottom: 16px" },
                        "If you have a specific SharePoint content url you would like to access, "
                            + "enter it here. Leaving this blank will take you to your default SharePoint view.",
                    ),
                    body: textBox,
                }).show();
            } else {
                openPicker();
            }
        };
    });
}

export function o365AuthAndAdminConsent(onFinish: (authWindow: Window) => void): void {
    generateTokenAndRedirectUri(AuthSource.GRAPH_USER).then((response) => {
        const authUrl = microsoftGraphAuthURL(
            response,
            "files.read.all offline_access Group.Read.All Directory.AccessAsUser.All",
        );
        Window.openCentered(authUrl, "Office 365 User Login");
        window.onGraphUserComplete = (authWindow) => {
            generateTokenAndRedirectUri(AuthSource.GRAPH_APP).then(
                (response: SecurityTokenResponse) => {
                    Rest.get("/check-admin-consent.rest", { state: response.token }).then(
                        (data) => {
                            if (data.consented && data.isAdmin) {
                                onFinish(authWindow);
                            } else {
                                if (!data.isAdmin) {
                                    authWindow.close();
                                    const redirectURL = new URL(response.redirectUri);
                                    redirectURL.searchParams.set("state", response.state);
                                    Window.openCentered(redirectURL);
                                } else {
                                    const adminConsentUrl = microsoftAdminConsentUrl(response);
                                    authWindow.close();
                                    Window.openCentered(adminConsentUrl, "Office 365 Upload");
                                    window.onGraphAppComplete = (authWindow) => {
                                        onFinish(authWindow);
                                    };
                                }
                            }
                        },
                    );
                },
            );
        };
    });
}

export function initSharefile(exitFunction: ExitFunction) {
    generateTokenAndRedirectUri(AuthSource.SHAREFILE).then((response: SecurityTokenResponse) => {
        const url = sharefileAuthUrl(response);
        Window.openCentered(url, "ShareFile login and file picker");
        // attach this function to the window object so that the child window can call via window.opener
        window.onSharefileComplete = function (authWindow: Window) {
            ga_event("File Picker", "Sharefile Submitted");
            // Close child window after we successfully get auth info
            authWindow.close();
            const retFiles: CloudFile[] = [];
            new SharefilePicker.Picker((files: SharefilePicker.Item[]) => {
                files.forEach((doc) => {
                    retFiles.push(
                        new CloudFile({
                            id: doc.id,
                            source: UploadSource.SHAREFILE,
                            name: doc.name,
                            size: doc.size,
                            isFolder: doc.fileType === "folder",
                        }),
                    );
                });
                appendCloudFolderName(retFiles);
                checkValidFileSelection(retFiles).then((valid) => {
                    if (valid) {
                        exitFunction(retFiles, UploadSource.SHAREFILE);
                    }
                });
            });
        };
    });
}

export function initSlack(exitFunction: ExitFunction): void {
    if (JSP_PARAMS.Server.isFedRamp) {
        // disable in fedramp for now
        throw new Error("Not allowed in FedRAMP");
    }
    generateTokenAndRedirectUri(AuthSource.SLACK).then((response: SecurityTokenResponse) => {
        // construct login url
        const authorizeUrl = slackAuthURL(response, "discovery:read");
        Window.openCentered(authorizeUrl, "Slack login");
        // attach this function to the window object so that the child window can call via window.opener
        window.onSlackComplete = function (authWindow: Window) {
            authWindow.close();
            exitFunction(null, null);
        };
        // Slack's 2 factor authorization turns window.opener into null, likely because it crosses domains
        // see: https://stackoverflow.com/questions/18625733/how-do-i-get-around-window-opener-cross-domain-security
        // BroadcastChannel doesn't seem to be supported by IE
        const broadcastChannel = new BroadcastChannel("slack");
        broadcastChannel.onmessage = (messageEvent) => {
            if (messageEvent.data === "slack_auth") {
                exitFunction(null, UploadSource.SLACK);
            }
        };
    });
}

// won't work if isFedRamp because the backend won't allow it.
export function initDirectLink(exitFunction: ExitFunction): void {
    const urlField = new TextBox();
    const dialog = QueryDialog.create({
        title: "Direct download link",
        prompt: Dom.div([
            Dom.p(
                "Allows for links pointing directly to a file without any authentication required (S3 pre-signed URLs, for example).",
            ),
            Dom.node(urlField),
        ]),
        onSubmit: () => {
            const url: string = urlField.getValue().trim();
            if (!url) {
                return false;
            }
            ga_event("File Picker", "Direct Link Submitted");
            Rest.get(`/parcel/${Project.CURRENT.parcel}/cloudAuth/getFileNameAndSizeFromUrl.rest`, {
                url: url,
            })
                .then((nameAndSize: { name: string; size: number }) => {
                    Util.destroy(urlField);
                    // pass the url along as a single file where the id is the url
                    const urlAsFiles: CloudFile[] = [
                        new CloudFile({
                            id: url,
                            source: UploadSource.DIRECT_LINK,
                            name: nameAndSize.name,
                            size: nameAndSize.size,
                        }),
                    ];
                    exitFunction(urlAsFiles, UploadSource.DIRECT_LINK);
                    // can't return from here so we need to manually destroy the dialog
                    Util.destroy(dialog);
                })
                .catch(() => {
                    if (dialog.isOpen()) {
                        Dialog.ok(
                            "Invalid URL",
                            "The URL was invalid, the download was the wrong type, or we were unable to extract the file's name and size.",
                        );
                        dialog._submitButton.setDisabled(false);
                    }
                });
            dialog._submitButton.setDisabled(true);
        },
        onCancel: function () {
            Util.destroy(urlField);
            return true;
        },
    });
}

// gets called in the popup window after the authentication with an external service finishes
// opens the filepicker for Box and Google Drive inside the popup, but returns
// to the parent window for Dropbox and OneDrive so that the parent window
// can launch the filepicker popup.
export function afterAuth(
    filePickerInfo: any,
    authToken: string,
    authSourceName: string,
    missingPermissions: string[],
): void {
    const authSource: AuthSource = AuthSource[authSourceName];
    if (authSource === AuthSource.BOX) {
        const picker = new Box.FilePicker();

        picker.addListener("choose", (items) => {
            // callback must be attached to window object when opening popup
            window.opener?.onBoxComplete(items, window);
        });

        picker.addListener("cancel", () => {
            window.close();
        });
        picker.show(filePickerInfo.rootFolderId, authToken, {
            type: "file,folder",
            canSetShareAccess: false,
            logoUrl: window.location.origin + "/images/everlaw-logo.png",
        });
    } else if (authSource === AuthSource.GOOGLE_DRIVE) {
        const restricted = window.opener?.restrictedGoogleDrive;
        gapi.load("picker", {
            callback: () => {
                const picker = new google.picker.PickerBuilder()
                    .addView(
                        new google.picker.DocsView()
                            .setMode(google.picker.DocsViewMode.LIST)
                            .setOwnedByMe(true)
                            .setSelectFolderEnabled(!restricted)
                            .setIncludeFolders(true),
                    )
                    .addView(
                        new google.picker.DocsView()
                            .setMode(google.picker.DocsViewMode.LIST)
                            .setOwnedByMe(false)
                            .setSelectFolderEnabled(!restricted)
                            .setIncludeFolders(true),
                    )
                    .addView(
                        new google.picker.DocsView()
                            .setMode(google.picker.DocsViewMode.LIST)
                            .setSelectFolderEnabled(!restricted)
                            .setIncludeFolders(true)
                            // This needs to be its own view (setting it to true means you only get
                            // team drive files).
                            .setEnableTeamDrives(true),
                    )
                    .setOAuthToken(authToken)
                    .setAppId(filePickerInfo.appId)
                    // callback must be attached to window object when opening popup
                    .setCallback((response: google.picker.Response) => {
                        window.opener?.onGoogleDriveComplete(response, window);
                    })
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
                    .setTitle(`Select ${restricted ? "Files" : "Files/Folders"}`)
                    .setSize(window.innerWidth, window.innerHeight)
                    .build();
                picker.setVisible(true);
            },
        });
    } else if (authSource === AuthSource.VAULT) {
        if (missingPermissions && missingPermissions.length !== 0) {
            window.opener?.onGoogleVaultPartialPermissionError(window);
        } else {
            //We have our own UI for the vault file picker which we'll open in the original window.
            window.opener?.onGoogleVaultComplete?.(authToken, window);
        }
    }
    // Dropbox and onedrive both have their own popups, so we return to the
    // opening window to let them open the popup rather than building the
    // filepicker inside the current authentication popup.
    // If the user has popups blocked in their browser, the on...Complete functions may not be
    // defined.
    else if (authSource === AuthSource.DROPBOX) {
        window.opener?.onDropboxComplete(window);
    } else if (authSource === AuthSource.GRAPH_USER) {
        window.opener?.onGraphUserComplete(window, authToken);
    } else if (authSource === AuthSource.GRAPH_APP) {
        window.opener?.onGraphAppComplete(window);
    } else if (authSource === AuthSource.SHAREFILE) {
        // Using own UI which we'll open in the original window.
        window.opener?.onSharefileComplete(window);
    } else if (authSource === AuthSource.SLACK) {
        // if the user had to sign in using 2 factor authorization, window.opener will be null
        if (!window.opener) {
            // BroadcastChannel doesn't seem to be supported by IE
            const broadcastChannel = new BroadcastChannel("slack");
            broadcastChannel.postMessage("slack_auth");
            window.close();
        } else {
            window.opener?.onSlackComplete(window);
        }
    } else {
        throw new Error("Unknown AuthSource");
    }
}

// add .zip to the end of any folder from a cloud upload source.
function appendCloudFolderName(files: FileWithId[]): void {
    files.forEach((f) => f.isFolder && (f.name += ".zip"));
}

function permissionErrorDialog(connectorName: string, permissionsBody?: HTMLElement) {
    const body = Dom.div(
        { class: "error-dialog" },
        Dom.p({ class: "error-instruct" }, `Missing permission for ${connectorName}:`),
        Dom.p(
            { class: "error-detail" },
            `Everlaw hasn’t been granted the appropriate permissions to complete this upload.  Please try again and authorize all requested permissions.`,
        ),
        permissionsBody,
    );
    Dialog.ok("Error", body, undefined, "456px");
}
